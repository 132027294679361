.toggle.checkbox {
    background-color: transparent  !important;
    border-radius: 500rem;
  }
  
  .ui.toggle.checkbox input[type="checkbox"]:checked ~ .box:before,
  .ui.toggle.checkbox input[type="checkbox"]:checked ~ label:before,
  .ui.toggle.checkbox input[type="radio"]:checked ~ .box:before,
  .ui.toggle.checkbox input[type="radio"]:checked ~ label:before {
    background-color: #e0ba8c!important;
  }