/* General app settings */

html,
body {
  height: 100%;
  width: 100%;
  background-color: #f7f4f0;
  min-height: 100%;
  min-width: 100%;  
  font-size: 14px !important;
}

.element::-webkit-scrollbar { 
  width: 0 !important;
}

::-webkit-scrollbar { 
  display: none; 
}

.modals {
  overflow-y: auto;
}

.ui.fullscreen.modal {
  height: fit-content;
}

.page__content {
  background-color: #f7f4f0 !important;
}

/* App fonts */

p {
  font-family: "Source Sans Pro", sans-serif !important;
}

body {
  font-family: "Source Sans Pro", sans-serif !important;
}

div {
  font-family: "Source Sans Pro", sans-serif !important;
}

.ui.header {
  font-family: "Source Sans Pro", sans-serif !important;
}

p,
label,
Text {
  line-height: 140%;
}

a,
a:visited {
  color: grey;
}

h1, h2, h3, h4 {
  font-weight: 500;
}


/* Border colors for dropdowns */

.ui.selection.dropdown {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown:hover {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown:hover .menu {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown .menu {
  border-color: #6B9D9F;
}

.ui.selection.dropdown:focus {
  border-color: #6B9D9F;
}

.ui.selection.dropdown:focus .menu {
  border-color: #6B9D9F;
}


/* Buttons */

button {
  background-color: #868D8E;
  color: white;
  text-decoration: none;
  width: auto;
  height: 32px;
}

button:hover {
  cursor: pointer;
  opacity: 0.85;
  transition: opacity 500ms;
}

.ui.button {
  background-color: #868D8E;
  color: white;
  text-decoration: none;
  width: auto;
  height: 32px;
}

.ui.button:hover {
  background-color: #868D8E;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 500ms;
}

.ui.primary.button {
  background-color: #6B9D9F;
  color: white;
}

.ui.primary.button:hover {
  background-color: #6B9D9F;
  opacity: 0.75;
  transition: opacity 500ms;
  color: white;
}

.ui.positive.button {
  background-color: #4C8440;
  color: white;
}

.ui.positive.button:hover {
  background-color: #4C8440;
  opacity: 0.75;
  transition: opacity 500ms;
  color: white;
}

.ui.negative.button {
  background-color: #C36126;
  color: white;
}

.ui.negative.button:hover {
  background-color: #C36126;
  opacity: 0.75;
  transition: opacity 500ms;
  color: white;
}

.ui.button {
  height: initial;
}


/* Progress bars */

.ui.green.progress .bar {
  background-color: #4C8440;
}
  
.ui.yellow.progress .bar {
  background-color: #F0BD38;
}
  
.ui.red.progress .bar {
  background-color: #C36126;
}
  


/* Toasters */

.Toastify__toast--success {
  background: #4C8440;
  color: white,
}

.Toastify__toast--warning {
  background: #F0BD38;
  color: white,
}

.Toastify__toast--error {
  background: #C36126;
  color: white,
}